<ion-item id="cancellationPolicyModal" detail="true" button="true" lines="none"
          class="ion-no-padding">
    <ion-icon slot="start" name="close-circle-outline"></ion-icon>
    <ion-label class="gb-small-text-size">
        Cancellation policy
    </ion-label>
</ion-item>

<ion-modal trigger="cancellationPolicyModal">
    <ng-template>
        <ion-header>
            <ion-toolbar class="gb-top-toolbar">
                <ion-buttons slot="start">
                    <ion-button (click)="close()">
                        <ion-icon slot="icon-only" color="dark" name="close-outline"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content *ngIf="provider$ | async as provider">
            <ion-list *ngIf="provider?.loading; else itemsLoaded">
                <ion-list-header>
                    <ion-skeleton-text animated class="small-width"></ion-skeleton-text>
                </ion-list-header>
                <ion-item *ngFor="let j of [1, 2, 3]">
                    <ion-thumbnail slot="start">
                        <ion-skeleton-text class="xlarge-width" animated></ion-skeleton-text>
                    </ion-thumbnail>
                    <ion-label>
                        <ion-skeleton-text animated class="medium-width"></ion-skeleton-text>
                        <ion-skeleton-text animated class="large-width"></ion-skeleton-text>
                    </ion-label>
                </ion-item>
            </ion-list>

            <ng-template #itemsLoaded>
                <ion-list>
                    <ion-list-header class="ion-padding-bottom">
                        Cancellation Policy
                    </ion-list-header>

                    <ng-container *ngIf="provider?.data?.provider?.policies?.cancellation_policies?.length; else noCancellationPolicy">
                        <ion-item *ngFor="let policy of provider?.data?.provider?.policies?.cancellation_policies">
                            <ion-label class="ion-text-wrap gb-medium-text-size">
                                A cancellation charge will apply if the reservation is canceled

                                <ion-text *ngIf="policy.charge_time_offset_min && policy.charge_time_offset_min < 0">
                                    within the last {{policy.charge_time_offset_min * -1 | getHoursAndMinutes}} prior to the reservation start time
                                </ion-text>
                                <ion-text *ngIf="policy.charge_time_offset_min && policy.charge_time_offset_min > 0">
                                    after {{policy.charge_time_offset_min | getHoursAndMinutes}} from the reservation start time
                                </ion-text>
                                <ion-text *ngIf="policy.fixed_amount as fixedAmount">
                                    in the amount of {{fixedAmount / 100 | currency: 'USD'}}
                                </ion-text>
                                <ion-text *ngIf="policy.percent as percentage">
                                    in the amount of {{percentage * 100}}% of the reservation total
                                </ion-text>
                            </ion-label>
                        </ion-item>
                    </ng-container>
                    <ng-template #noCancellationPolicy>
                        <ion-item class="ion-padding-bottom">
                            <ion-label>
                                There is no cancellation policy defined
                            </ion-label>
                        </ion-item>
                    </ng-template>
                </ion-list>
            </ng-template>
        </ion-content>
    </ng-template>
</ion-modal>