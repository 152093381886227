import * as Types from './generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserConfirmDeleteAccountMutationVariables = Types.Exact<{
  code?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UserConfirmDeleteAccountMutation = { __typename?: 'Mutation', userConfirmDeleteAccount: { __typename?: 'ConfirmDeleteAccountResponse', message?: string | null, confirmed: boolean } };

export const UserConfirmDeleteAccountDocument = gql`
    mutation UserConfirmDeleteAccount($code: String) {
  userConfirmDeleteAccount(data: {code: $code}) {
    message
    confirmed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserConfirmDeleteAccountGQL extends Apollo.Mutation<UserConfirmDeleteAccountMutation, UserConfirmDeleteAccountMutationVariables> {
    document = UserConfirmDeleteAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }