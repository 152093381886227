import {Component, OnInit, ViewChild} from '@angular/core';

import {IonContent, IonRouterOutlet, Platform} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Capacitor} from '@capacitor/core';
import {Store} from '@ngrx/store';
import {State} from './reducers';
import {UserAuthStateChanged} from './actions/user.actions';
import firebase from 'firebase/compat/app';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Hydrate, Initialize, OpenUrl} from './actions/app.actions';
import {updateMomentCalendar} from './utils/DateTimeUtils';
import {environment} from '../environments/environment';
import {
    changeToDarkStatusBarColor,
    onDidDismissModalDarkBackground,
    presentModalDarkBackground
} from './utils/StatusBarUtils';
import {StatusBar} from '@capacitor/status-bar';
import {SplashScreen} from '@capacitor/splash-screen';
import {App} from '@capacitor/app';
import {FirebaseAnalytics} from '@capacitor-community/firebase-analytics';
import {addImportantDocumentStyleProperty, recordException} from './utils/app.utils';
import {Keyboard} from "@capacitor/keyboard";
import {filter, map, switchMap, takeWhile} from "rxjs/operators";
import {connectAuthEmulator, getAuth} from "@angular/fire/auth";
import {fromFirebaseUser} from "./models/user";
import {register as registerSwiper} from "swiper/element/bundle";
import {activeProviderId, activeProviderSlug, ProviderServicesService} from "./services/provider-services.service";
import {LoginPageComponent} from "./login-page/login-page.component";
import {SLUG_KEY_ROUTE} from "./utils/routes.utils";
import {PageMetadataService} from "./services/page-metadata.service";
import {GbModalController} from "@gobubbleapp/common-ui";
import chroma from "chroma-js";
import {registerLocaleData} from "@angular/common";
import localeEnUS from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';

registerSwiper();
registerLocaleData(localeEnUS, 'en-US');
registerLocaleData(localeEnGB, 'en-GB');
registerLocaleData(localeFr, 'fr-FR');

declare var window: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    @ViewChild(IonContent, { read: IonContent }) content: IonContent;
    @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;

    private static scriptLoaded = false;
    public isMobile: boolean;
    public isNative = Capacitor.isNativePlatform();
    public authStateLoaded = false;

    constructor(
        private platform: Platform,
        private afAuth: AngularFireAuth,
        private store$: Store<State>,
        private router: Router,
        private modalController: GbModalController,
        private route: ActivatedRoute,
        private providerService: ProviderServicesService,
        private pageMetadataService: PageMetadataService
    ) {
        this.isMobile = !this.platform.is('desktop');
        this.initializeApp();
    }

    ngOnInit() {

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                switchMap((e) => {
                    let currentRoute = this.route.root;

                    while (currentRoute.children.length > 0) {
                        currentRoute = currentRoute.children[0];
                    }

                    return currentRoute.paramMap.pipe(
                        map(params => ({params, route: (e as {url?: string}).url}))
                    );
                })
            )
            .subscribe(({params, route}) => {
                const slug = params.get(SLUG_KEY_ROUTE) ?? environment.defaultProviderSlug;
                const activeProvider = activeProviderSlug();

                if (!slug || slug === "undefined") {
                    console.log(`setting default page metadata`);
                    this.pageMetadataService.setTitle('RzyPro');
                    this.pageMetadataService.setFavicon('assets/icons/favicon.ico');
                    this.pageMetadataService.setDesc('Streamline Your Business');
                } else if (slug !== activeProvider) {
                    activeProviderSlug(slug);
                }

                this.providerService.getActiveProvider().valueChanges.pipe(
                    takeWhile(res => !res?.data?.providerBySlug?.id, true)
                ).subscribe(res => {
                    const providerId = res.data?.providerBySlug?.id;
                    if (providerId && providerId !== activeProviderId()) {
                        activeProviderId(providerId);

                        const publicProfile = res.data?.providerBySlug?.public_profile;
                        this.pageMetadataService.setTitle(publicProfile?.display_name);
                        this.pageMetadataService.setFavicon(publicProfile?.logo_image_url);
                        this.pageMetadataService.setDesc(publicProfile?.display_bio);
                        this.pageMetadataService.setSeoKeywords(publicProfile?.seo_keywords);

                        const locale = publicProfile?.locale;
                        if (locale?.locale) {
                            // this.accountService.cachedLocaleId.next(locale.locale);
                            registerLocaleData(locale.locale);
                        }

                        if (publicProfile?.primary_color) {
                            this.updateColor('--ion-color-primary', publicProfile.primary_color);
                        }

                        if (publicProfile?.secondary_color) {
                            this.updateColor('--ion-color-secondary', publicProfile.secondary_color);
                        }
                    }
                })

                // const rootPath = getRootPathArr();
                // const currentRouteArr = route
                //     ?.split("/")
                //     ?.filter((r: string) => !!r)
                //
                // const isRootPath = isEqual(currentRouteArr, rootPath);
                // this.hideHeader = !isRootPath;
                // const oneOfTabs = currentRouteArr?.length === 1
                //     && (currentRouteArr[0] === HOME_ROUTE
                //         || currentRouteArr[0] === RESERVATIONS_ROUTE
                //         || currentRouteArr[0] === 'profile');
                // this.showBackButton = !(isRootPath || currentRouteArr?.length === 0 || oneOfTabs);

                // this.onScroll();

                // setTimeout(() => {
                //     const header = document.querySelector('ion-header');
                //     const content = document.querySelector('ion-content');
                //     if (header && content) {
                //         // 16px is the default margin
                //         const navbarHeight = header.scrollHeight + 16;
                //         content.style.setProperty('--padding-top', `${navbarHeight}px`);
                //     }
                // }, 500);
            });
    }

    initializeApp() {
        console.log('initialize app: ' + `local=${environment.local}, prd=${environment.production}`);
        updateMomentCalendar();

        if (environment.local) {
            connectAuthEmulator(getAuth(), "http://0.0.0.0:9099");
        }

        this.platform.ready().then(() => {
            this.store$.dispatch(new Initialize());

            if (!AppComponent.scriptLoaded) {
                this.loadScript(environment.firebase.apiKey);
                AppComponent.scriptLoaded = true;
            }

            AppComponent.initializeSignInWithGoogle();

            console.log('router: ', this.router.url);
            App.addListener('appUrlOpen', data => {
                if (data && data.url) {
                    console.log(`url: ${data.url}`);
                    this.store$.dispatch(new OpenUrl(data.url));
                    console.log('router: ', this.router.url);
                    console.log('firebase dynamic link');
                }
            });
            if (this.platform.is('desktop')) {
                // Using community firebase analytics instead of angularfire version because it has issues
                // with capacitor's iOS custom scheme
                FirebaseAnalytics.initializeFirebase(environment.firebase).catch(e => {
                    console.error('Error initializing firebase analytics', e);
                    recordException('Error initializing firebase analytics');
                    recordException(e);
                });

                if (window.location.href) {
                    console.log(`url: ${window.location.href}`);
                    this.store$.dispatch(new OpenUrl(window.location.href));
                }
            }
            this.store$.dispatch(new Hydrate());
            this.afAuth.authState.subscribe((user: firebase.User | null) => {
                this.authStateLoaded = true;
                console.log(`user auth changed: loggedIn=${!!user}`);
                this.store$.dispatch(new UserAuthStateChanged(fromFirebaseUser(user)));
                SplashScreen.hide().then(() => {
                    if (Capacitor.isPluginAvailable('StatusBar')) {
                        StatusBar.show()
                            .then(() => changeToDarkStatusBarColor())
                            .catch(e => console.error('error settings status bar color', e));
                    }
                }).catch(e => console.error("error hiding splash screen", e));
            });

            if (this.platform.is('ios')) {
                Keyboard.addListener('keyboardWillShow', info => {
                    const content: NodeListOf<HTMLElement> = document.querySelectorAll('ion-content');
                    if (content.length > 0) {
                        content[content.length - 1].style.setProperty('--keyboard-offset', `${info.keyboardHeight}px`);
                    }
                })

                Keyboard.addListener('keyboardWillHide', () => {
                    const content: NodeListOf<HTMLElement> = document.querySelectorAll('ion-content');
                    if (content.length > 0) {
                        content[content.length - 1].style.removeProperty('--keyboard-offset');
                    }
                })
            }
        });
    }

    loadScript(key: string) {
        const node = document.createElement('script'); // creates the script tag
        // sets the source (insert url in between quotes)
        node.src = 'https://maps.googleapis.com/maps/api/js?key=' + key + '&v=3.exp&libraries=places,geometry';
        node.type = 'text/javascript'; // set the script type
        node.async = true; // makes script run asynchronously
        node.charset = 'utf-8';
        // append to head of document
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    private static initializeSignInWithGoogle() {
        if (Capacitor.isNativePlatform()) {
            // TODO: replace
            // GoogleAuth.initialize();
        }
    }

    async openLoginPane() {
        const modal = await this.modalController.create({
            component: LoginPageComponent,
            presentingElement: this.routerOutlet?.nativeEl
        });
        onDidDismissModalDarkBackground(modal);
        return presentModalDarkBackground(modal).then(() => modal);
    }

    private updateColor(cssColorVar: string, color: string) {
        const chromaColor = chroma(color);

        const colorRgb = chromaColor.rgb(); // Returns an array [r, g, b]
        const colorRgbString = `${colorRgb[0]}, ${colorRgb[1]}, ${colorRgb[2]}`; // Format as string for CSS

        const luminance = chromaColor.luminance(); // Get the luminance (brightness) of the color
        const contrastColor = luminance > 0.5 ? 'black' : 'white'; // Choose black or white based on luminance
        const contrastColorRgb = chroma(contrastColor).rgb(); // Get the RGB values of the contrast color
        const contrastColorRgbString = `${contrastColorRgb[0]}, ${contrastColorRgb[1]}, ${contrastColorRgb[2]}`; // Format as string for CSS

        const shade = chromaColor.darken(0.2); // 20% darker
        const tint = chromaColor.brighten(0.2); // 20% brighter

        addImportantDocumentStyleProperty(cssColorVar, color);
        addImportantDocumentStyleProperty(`${cssColorVar}-rgb`, colorRgbString);
        addImportantDocumentStyleProperty(`${cssColorVar}-contrast`, contrastColor);
        addImportantDocumentStyleProperty(`${cssColorVar}-contrast-rgb`, contrastColorRgbString);
        addImportantDocumentStyleProperty(`${cssColorVar}-shade`, shade.hex());
        addImportantDocumentStyleProperty(`${cssColorVar}-tint`, tint.hex());
    }

    // @HostListener('ionScroll', ['$event'])
    // onScroll($event?: any) {
    //     const scrollElement = $event?.detail;
    //     const scrollTop = scrollElement?.scrollTop ?? 0;
    //     this.headerHiddenOnScroll = scrollTop > this.lastScrollTop;
    //     this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    //     console.log(`headerHidden: ${this.headerHiddenOnScroll}`);
    // }
}
