<!--    <ion-header>-->
<!--        <ion-toolbar class="gb-top-toolbar">-->
<!--            <ion-buttons slot="start">-->
<!--                <ion-back-button defaultHref="/" text="" color="dark"></ion-back-button>-->
<!--            </ion-buttons>-->
<!--            <ion-buttons slot="end">-->
<!--                <ion-button (click)="helpButtonClicked()" color="dark">-->
<!--                    <ion-icon slot="start" color="dark" name="help-buoy-outline"></ion-icon>-->
<!--                    <ion-label class="help-button-text">Help</ion-label>-->
<!--                </ion-button>-->
<!--            </ion-buttons>-->
<!--        </ion-toolbar>-->
<!--    </ion-header>-->

<ng-template #toolbarEndButtons>
<!--    <ion-buttons slot="end">-->
<!--        <ion-button (click)="helpButtonClicked()" color="dark">-->
<!--            <ion-icon slot="start" color="dark" name="help-buoy-outline"></ion-icon>-->
<!--            <ion-label class="help-button-text">Help</ion-label>-->
<!--        </ion-button>-->
<!--    </ion-buttons>-->
</ng-template>

<app-page
        showBackButton="true"
        [defaultBackRoute]="DEFAULT_BACK_ROUTE"
        [toolbarTemplate]="toolbarEndButtons"
        *ngIf="reservationQuery$ | async as reservationQuery">

    <ion-spinner *ngIf="reservationQuery.loading; else loaded"
                 class="big-loading-spinner"
                 color="primary"
                 name="crescent"
                 duration="undefined"></ion-spinner>
    <ng-template #loaded>
        <div class="multi-column-boxes left-container-bigger"
             *ngIf="reservationQuery.data?.reservation as reservation; else noReservation">

            <div class="column-box no-shadow no-padding">
                <div class="gb-page-title-container">
                    <ion-label class="gb-page-title">
                        Reservation
                    </ion-label>
                </div>
                <p class="status-description" *ngIf="reservation.status === reservationStatusEnum.InProgress">
                    {{reservation.expert_first_name ? (reservation.expert_first_name | titlecase) : 'The expert'}}
                    is working on your car and is expected finish in
                    <ion-text color="primary" *ngIf="reservation.in_progress_estimated_end_time; else noEndTime">
                        {{reservation.in_progress_estimated_end_time | amFromUnix | amDifference: now :'minutes' : true | number: '1.0-0' | positiveOrZeroInt }} minutes.
                    </ion-text>
                    <ng-template #noEndTime>
                        <ion-text color="primary">
                            soon.
                        </ion-text>
                    </ng-template>
                </p>
                <p class="status-description" *ngIf="reservation.status === reservationStatusEnum.EnRoute">
                    {{reservation.expert_first_name ? (reservation.expert_first_name | titlecase) : 'The expert'}}
                    is on the way and should be arriving soon
                </p>
                <p class="status-description" *ngIf="reservation.status === reservationStatusEnum.Canceled">
                    Reservation has been cancelled
                </p>
                <p class="status-description" *ngIf="reservation.status === reservationStatusEnum.Scheduled">
                    You will receive a message once your reservation is confirmed as well as other updates related to your reservation.
                </p>
                <p class="status-description" *ngIf="reservation.status === reservationStatusEnum.Confirmed">
                    Your reservation has been confirmed and assigned to an expert. You will receive a message with updates related to your reservation.
                </p>
                <div class="section">
                    <ion-item *ngIf="reservation.location as location" class="gb-transparent detail-item" lines="none">
                        <ion-icon name="location-outline" color="medium" slot="start"></ion-icon>
                        <div class="multi-line-labels">
                            <ion-label class="first-line ion-text-wrap" *ngIf="location.name">
                                {{location.name | titlecase}}
                            </ion-label>
                            <ion-label class="second-line">
                                {{location.address}}
                            </ion-label>
                        </div>
                    </ion-item>
                    <ion-item *ngIf="reservation.time_slot as timeSlot" class="gb-transparent detail-item" lines="none">
                        <ion-icon name="time-outline" color="medium" slot="start"></ion-icon>
                        <div class="multi-line-labels"
                             *ngIf="reservation.completed_at; else notCompleted">
                            <ion-label class="second-line">Completed at</ion-label>
                            <ion-label class="first-line ion-text-wrap">
                                {{reservation.completed_at*1000 | amDateFormat: 'h:mm A'}}
                            </ion-label>
                        </div>
                        <ng-template #notCompleted>
                            <div class="multi-line-labels"
                                 *ngIf="reservation.status === reservationStatusEnum.InProgress
                             && reservation.in_progress_start_time; else notInProgress">
                                <ion-label class="second-line">Completed by</ion-label>
                                <ion-label class="first-line">
                                    {{reservation.in_progress_estimated_end_time*1000 | amDateFormat: 'h:mm A'}}
                                </ion-label>
                            </div>
                            <ng-template #notInProgress>
                                <div class="multi-line-labels">
                                    <ion-label class="second-line">Scheduled for</ion-label>
                                    <ion-label class="first-line">
                                        {{timeSlot.start_time*1000 | amDateFormat:'h:mm A'}} -
                                        {{timeSlot.end_time*1000 | amDateFormat:'h:mm A'}}
                                    </ion-label>
                                </div>
                            </ng-template>
                        </ng-template>
                    </ion-item>
                    <ion-item *ngIf="reservation.time_slot as timeSlot" class="gb-transparent detail-item" lines="none">
                        <ion-icon name="calendar-outline" color="medium" slot="start"></ion-icon>
                        <ion-label>
                            <h3>
                                {{timeSlot.start_time*1000 | amDateFormat:'ddd MMM Do, yyyy'}}
                            </h3>
                        </ion-label>
                    </ion-item>
                    <ion-item *ngIf="reservation.confirmation_code as confirmationCode"
                              class="gb-transparent detail-item" lines="none">
                        <ion-icon name="receipt-outline" color="medium" slot="start"></ion-icon>
                        <div class="multi-line-labels">
                            <ion-label class="second-line">Confirmation code</ion-label>
                            <ion-label class="first-line">{{confirmationCode | uppercase}}</ion-label>
                        </div>
                    </ion-item>
                </div>
                <div *ngIf="communicationStatus$ | async as communicationStatus" class="section">
                    <div class="customer-communication">
                        <div class="multi-line-labels left">
                            <ion-label class="second-line">
                                Assigned pro
                            </ion-label>
                            <ion-label class="gb-section-title">
                                {{reservation.expert_first_name || 'Unknown name'}}
                            </ion-label>
                        </div>
                        <div class="right">
                            <ion-button
                                    fill="outline"
                                    [disabled]="!communicationStatus?.can_chat"
                                    (click)="openChatBox(reservation)">
                                <ion-icon slot="start" name="chatbox-outline"></ion-icon>
                                <ion-label>Chat</ion-label>
                            </ion-button>
                            <ion-button
                                    fill="outline"
                                    [disabled]="!communicationStatus?.can_call"
                                    (click)="placeCall()">
                                <ion-icon slot="start" name="call-outline"></ion-icon>
                                <ion-spinner *ngIf="communicationLoading$ | async; else communicationLoaded" name="dots"></ion-spinner>
                                <ng-template #communicationLoaded>
                                    <ion-label>Call</ion-label>
                                </ng-template>
                            </ion-button>
                        </div>
                    </div>
                    <ion-label class="gb-xsmall-text-size" color="medium">
                        {{!communicationStatus?.can_chat && !communicationStatus?.can_call ? 'You will be able to call or chat with the expert closer to the appointment time. ' : ''}}
                        For security and privacy, your number is not visible to the customer.
                    </ion-label>
                </div>
            </div>

            <div class="column-box vertical-padding-on-small-screen">
                <app-order-details
                        [reservation]="reservation"
                        [showEditButtons]="false"
                        [expandIfSingleItem]="false"
                        [showScheduledPayments]="false"
                ></app-order-details>
                <div *ngIf="reservation?.cancellation?.can_cancel">
                    <div class="ion-padding-bottom">
                        <ion-button expand="block" class="gb-footer-button" color="danger"
                                    [disabled]="cancellationButtonLoading"
                                    (click)="cancelRequest(reservation?.cancellation?.cancellation_fee)">
                            <ion-spinner name="dots" duration="undefined"
                                         *ngIf="cancellationButtonLoading; else loaded"></ion-spinner>
                            <ng-template #loaded>
                                <ion-label>
                                    Cancel
                                </ion-label>
                            </ng-template>
                        </ion-button>

                        <app-cancellation-policy [provider$]="provider$"></app-cancellation-policy>

                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #noReservation>
        <ion-item lines="none" class="gb-transparent ion-text-center">
            <ion-label color="danger" class="ion-text-wrap">
                The reservation is invalid or no longer exists.
            </ion-label>
        </ion-item>
    </ng-template>
</app-page>
