import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeyValue} from '@angular/common';
import Fuse from 'fuse.js';

@Component({
    selector: 'app-searchable-items',
    templateUrl: './searchable-items.component.html',
    styleUrls: ['./searchable-items.component.scss'],
})
export class SearchableItemsComponent implements OnInit {

    @Input() items: KeyValue<string, string>[] = [];
    @Input() selectedItem: string;
    @Output() itemSelected = new EventEmitter<KeyValue<string, string>>();

    private fuse: Fuse<KeyValue<string, string>>;
    public filteredItems: KeyValue<string, string>[] = [];

    constructor() {
    }

    ngOnInit() {
        const options = {
            includeScore: true,
            keys: ['key', 'value']
        };

        this.fuse = new Fuse(this.items, options);
    }

    searchValueChanged(event: any) {
        if (event?.detail?.value) {
            this.filteredItems = this.fuse.search(event.detail.value)
                .sort((a, b) => (a.score || 0) - (b.score || 0))
                .map(result => result.item);
        } else {
            this.filteredItems = [];
        }
    }

    onItemSelect(item: KeyValue<string, string>) {
        this.itemSelected.emit(item);
    }
}
