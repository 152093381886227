import {Pipe, PipeTransform} from '@angular/core';
import {ProviderServicesService} from "../services/provider-services.service";
import {take} from "rxjs/operators";
import {getCountryFromLocale} from "../utils/app.utils";
import {AsYouType, isSupportedCountry} from "libphonenumber-js";

@Pipe({
    name: 'positiveOrZeroInt'
})
export class PositiveOrZeroPipeInt implements PipeTransform {
    transform(num: string): number {
        const int = parseInt(num, 10);
        if (isNaN(int)) {
            return 0;
        }
        return Math.max(0, int);
    }
}

@Pipe({
    name: 'stringReplace'
})
export class StringReplacePipe implements PipeTransform {
    transform(value: string, regexValue: string, replaceValue: string): string {
        return value.replace(new RegExp(regexValue, 'g'), replaceValue);
    }
}

@Pipe({
    name: 'formatPhoneNumber'
})
export class FormatPhoneNumberPipe implements PipeTransform {

    private asYouType: AsYouType;

    constructor(
        private providerServicesService: ProviderServicesService
    ) {
        // Default to US
        this.asYouType = new AsYouType('US');

        this.providerServicesService.getLocaleId()
            .pipe(take(1))
            .subscribe(localeId => {
                const countryCode = getCountryFromLocale(localeId);
                const supportedCountry = countryCode && isSupportedCountry(countryCode)
                    ? countryCode
                    : 'US';
                this.asYouType = new AsYouType(supportedCountry);
            });
    }

    transform(phoneNumber: string) {
        return this.asYouType.input(phoneNumber);
    }
}