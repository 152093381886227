<ion-list>
    <ion-searchbar showCancelButton="never" class="small-searchbar"
                   [debounce]="100"
                   (ionInput)="searchValueChanged($event)"></ion-searchbar>
    <ion-item *ngFor="let item of (filteredItems.length > 0 ? filteredItems : items)"
              lines="full"
              button="true"
              class="gb-transparent"
              (click)="onItemSelect(item)">
        <ion-icon *ngIf="item.key === selectedItem" name="checkmark-outline" color="primary"></ion-icon>
        <ion-label>{{item.value}}</ion-label>
    </ion-item>
</ion-list>
