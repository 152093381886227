import {ModalController, ToastController} from '@ionic/angular';
import {RequestItemDetails} from '@gobubbleapp/models';
import {FirebaseAnalytics} from '@capacitor-community/firebase-analytics';
import {Capacitor} from "@capacitor/core";
import {FirebaseCrashlytics} from "@capacitor-firebase/crashlytics";
import {ReservationItemPropsFragment} from "../gql/reservation.graphql-gen";

export const PRIVACY_HELP_ARTICLE = "6717041503635";
export const TERMS_HELP_ARTICLE = "6717038570643";
export const CUSTOMER_SUPPORT_CATEGORY = "360004521753";

export function showToastMessage(toastController: ToastController, message: string,
                                 color: 'success' | 'danger' | 'warning',
                                 position: 'top' | 'bottom' = 'bottom',
                                 duration: number = 5000) {
    return toastController.create({
        duration,
        message: message,
        color,
        position,
        buttons: [
            {
                text: 'Close',
                role: 'cancel'
            }
        ]
    }).then(toast => toast.present());
}

export function showErrorMessage(toastController: ToastController,
                                 message: string | any,
                                 position: 'top' | 'bottom' = 'bottom') {
    if (!message) {
        return Promise.resolve();
    }
    // 'auth/provider-already-linked'
    console.log('received error message: ' + message?.toString());
    let strippedMessage: string;
    if (typeof message === 'object') {
        if (message.message) {
            strippedMessage = message.message;
        } else {
            strippedMessage = 'Internal error. Please try again';
        }
    } else {
        if (message.startsWith('Error: ')) {
            const splitMessage = message.split('Error: ');
            strippedMessage = splitMessage.slice(1).join();
        } else {
            strippedMessage = message;
        }
    }

    // This removes the suffix error messages from firebase.
    // Example: (auth/wrong-password).
    strippedMessage = strippedMessage?.replace(/\([a-zA-Z0-9-]+\/[a-zA-Z0-9-]+\)[.]*/g, "");

    strippedMessage = strippedMessage?.replace("Firebase: ", "");

    return showToastMessage(toastController, strippedMessage, 'danger', position, 5000);
}

export function showWarningMessage(toastController: ToastController, message: string,
                                   position: 'top' | 'bottom' = 'bottom',
                                   duration: number = 5000) {
    console.warn(message);
    return showToastMessage(toastController, message, 'warning', position, duration);
}

export function showSuccessMessage(toastController: ToastController, message: string,
                                   position: 'top' | 'bottom' = 'bottom',
                                   duration: number = 5000) {
    console.log(message);
    return showToastMessage(toastController, message, 'success', position, duration)
}

export async function recordException(message: string) {
    if (Capacitor.isNativePlatform()) {
        const {enabled} = await FirebaseCrashlytics.isEnabled();
        if (enabled) {
            FirebaseCrashlytics.recordException({message}).catch(e => {
                console.error('Error recording exception', e);
            });
        }
    }
    return Promise.resolve();
}

export interface AnalyticEventParams {
    currency?: string,
    value?: string | number,
    coupon?: string,
    items?: {
        item_id?: string;
        item_name?: string;
        item_category?: string;
        item_category2?: string;
        item_category3?: string;
        item_category4?: string;
        item_category5?: string;
    }[];
    [key: string]: any
}

export function logAnalyticEvent(name: string, params: AnalyticEventParams) {
    FirebaseAnalytics.logEvent({name, params}).catch(e => {
        console.error('Error recording analytic event, params: ' + JSON.stringify(params), e);
    });
}

export function logScreenView(screenName: string, screenClass?: any) {
    FirebaseAnalytics.setScreenName({screenName, nameOverride: screenClass}).catch(e => {
        console.error('Error recording analytic event', e);
    });
}

export function getReservationItemsLogAnalyticParams(items: Partial<ReservationItemPropsFragment>[]) {
    try {
        const analyticItems = [];
        let total = 0;
        for (const item of (items ?? [])) {
            for (const service of item.provider_services) {
                const washTypePrice = Math.round((service?.price ?? service?.option?.price ?? 0) / 100);
                analyticItems.push({
                    item_name: service.service?.displayProperties?.title?.substr(0, 100),
                    item_id: service.service.id,
                    item_category: service?.service?.service_category.toString(),
                    price: washTypePrice,
                    quantity: 1
                });
                total += washTypePrice;
            }
        }

        return {
            items: analyticItems,
            value: total,
            currency: 'usd'
        };

    } catch (e) {
        console.error('Error getting items to log params', e);
        return {};
    }
}

export function getItemsLogAnalyticParams(items: RequestItemDetails[]) {
    try {
        const analyticItems = [];
        let total = 0;
        for (const item of items) {
            const washTypePrice = Math.round(item.wash_type.plan.price / 100);
            analyticItems.push({
                item_name: item.wash_type.name ? item.wash_type.name.substr(0, 100) : '',
                item_id: item.wash_type.identifier.valueOf(),
                item_category: 'Wash package',
                price: washTypePrice,
                quantity: 1
            });
            total += washTypePrice;

            if (item.add_ons && item.add_ons.length > 0) {
                analyticItems.push(...item.add_ons.map(addOn => {
                    return {
                        item_name: addOn.name ? addOn.name.substr(0, 100) : '',
                        item_id: addOn.identifier.valueOf(),
                        item_category: 'Wash add-on',
                        price: Math.round(addOn.plan.price / 100),
                        quantity: 1
                    };
                }));
                total += item.add_ons
                    .map(addOn => Math.round(addOn.plan.price / 100))
                    .reduce((p, c) => p + c, 0);
            }
        }
        return {
            items: analyticItems,
            value: total,
            currency: 'usd'
        };

    } catch (e) {
        console.error('Error getting items to log params', e);
        return {};
    }
}

export function getExpertTipAnalytic(tipAmount: number) {
    return {
        items: [
            {
                item_id: 'expert_tip',
                item_name: 'Expert Tip',
                price: Math.round(tipAmount / 100),
                item_category: 'Tip',
            }
        ],
        value: Math.round(tipAmount / 100),
        currency: 'usd'
    };
}

export function dismissAllModals(modalController: ModalController) {
    return modalController.getTop()
        .then(v => v ? modalController.dismiss().then(() => dismissAllModals(modalController)) : Promise.resolve())
        .catch(e => {
            console.error('Error dismissing modals', e);
            return Promise.resolve();
        });
}

export function addImportantDocumentStyleProperty(key: string, val: string) {
    document.documentElement.style.setProperty(key, val, 'important');
}

export function getCountryFromLocale(locale: string) {
    const split = locale.split('-');
    if (split.length > 1) {
        return split[1].toUpperCase();
    }
    return undefined;
}