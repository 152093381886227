import * as Types from './generated/graphql-schema';

import { gql } from 'apollo-angular';
import { ProviderPromotionCodeFragmentDoc } from './fragments/coupons.graphql-gen';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProviderPublicProfilePropsFragment = { __typename?: 'ProviderPublicProfile', display_name?: string | null, display_bio?: string | null, logo_image_url?: string | null, show_display_name_by_logo?: boolean | null, banner_image_url?: string | null, personal_image_url?: string | null, primary_color?: string | null, secondary_color?: string | null, seo_keywords?: string | null, contact_info?: { __typename?: 'ProviderContactInfo', contact_phone?: string | null } | null, locale?: { __typename?: 'ProviderLocale', locale?: string | null, currency?: string | null } | null };

export type ProviderPopsFragment = { __typename?: 'Provider', id: string, public_profile?: { __typename?: 'ProviderPublicProfile', display_name?: string | null, display_bio?: string | null, logo_image_url?: string | null, show_display_name_by_logo?: boolean | null, banner_image_url?: string | null, personal_image_url?: string | null, primary_color?: string | null, secondary_color?: string | null, seo_keywords?: string | null, contact_info?: { __typename?: 'ProviderContactInfo', contact_phone?: string | null } | null, locale?: { __typename?: 'ProviderLocale', locale?: string | null, currency?: string | null } | null } | null, active_promotions?: Array<{ __typename?: 'PromotionCode', id: string, code?: string | null, restrictions?: { __typename?: 'PromotionCodeRestrictions', expires_at?: number | null, first_time_transaction?: boolean | null, min_vehicle_count?: number | null, minimum_amount?: number | null } | null, coupon?: { __typename?: 'Coupon', id: string, name?: string | null, amount_off?: number | null, percent_off?: number | null, auto_apply?: boolean | null, restrictions?: { __typename?: 'CouponRestrictions', expires_at?: number | null, first_time_transaction?: boolean | null, min_vehicle_count?: number | null, minimum_amount?: number | null } | null, display_properties?: { __typename?: 'CouponDisplayProperties', title?: string | null, description?: string | null, display_location?: Types.PromotionCodeDisplayLocation | null, display_order?: number | null, image_url?: string | null } | null } | null }> | null };

export type ProviderForCheckoutPropsFragment = { __typename?: 'Provider', id: string, policies?: { __typename?: 'ProviderPolicies', cancellation_policies?: Array<{ __typename?: 'CancellationPolicy', charge_time_offset_min?: number | null, fixed_amount?: number | null, percent?: number | null }> | null } | null };

export type GetActiveProviderQueryVariables = Types.Exact<{
  slug?: Types.Scalars['String'];
  hasSlug?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetActiveProviderQuery = { __typename?: 'Query', activeProviderSlug?: string | null, activeProviderHasSlug?: boolean | null, providerBySlug?: { __typename?: 'Provider', id: string, public_profile?: { __typename?: 'ProviderPublicProfile', display_name?: string | null, display_bio?: string | null, logo_image_url?: string | null, show_display_name_by_logo?: boolean | null, banner_image_url?: string | null, personal_image_url?: string | null, primary_color?: string | null, secondary_color?: string | null, seo_keywords?: string | null, contact_info?: { __typename?: 'ProviderContactInfo', contact_phone?: string | null } | null, locale?: { __typename?: 'ProviderLocale', locale?: string | null, currency?: string | null } | null } | null, active_promotions?: Array<{ __typename?: 'PromotionCode', id: string, code?: string | null, restrictions?: { __typename?: 'PromotionCodeRestrictions', expires_at?: number | null, first_time_transaction?: boolean | null, min_vehicle_count?: number | null, minimum_amount?: number | null } | null, coupon?: { __typename?: 'Coupon', id: string, name?: string | null, amount_off?: number | null, percent_off?: number | null, auto_apply?: boolean | null, restrictions?: { __typename?: 'CouponRestrictions', expires_at?: number | null, first_time_transaction?: boolean | null, min_vehicle_count?: number | null, minimum_amount?: number | null } | null, display_properties?: { __typename?: 'CouponDisplayProperties', title?: string | null, description?: string | null, display_location?: Types.PromotionCodeDisplayLocation | null, display_order?: number | null, image_url?: string | null } | null } | null }> | null } | null };

export type GetProviderForCheckoutQueryVariables = Types.Exact<{
  providerId?: Types.Scalars['String'];
}>;


export type GetProviderForCheckoutQuery = { __typename?: 'Query', activeProviderId?: string | null, provider?: { __typename?: 'Provider', id: string, policies?: { __typename?: 'ProviderPolicies', cancellation_policies?: Array<{ __typename?: 'CancellationPolicy', charge_time_offset_min?: number | null, fixed_amount?: number | null, percent?: number | null }> | null } | null } | null };

export const ProviderPublicProfilePropsFragmentDoc = gql`
    fragment ProviderPublicProfileProps on ProviderPublicProfile {
  display_name
  display_bio
  logo_image_url
  show_display_name_by_logo
  banner_image_url
  personal_image_url
  primary_color
  secondary_color
  seo_keywords
  contact_info {
    contact_phone
  }
  locale {
    locale
    currency
  }
}
    `;
export const ProviderPopsFragmentDoc = gql`
    fragment ProviderPops on Provider {
  id
  public_profile {
    ...ProviderPublicProfileProps
  }
  active_promotions {
    ...ProviderPromotionCode
  }
}
    ${ProviderPublicProfilePropsFragmentDoc}
${ProviderPromotionCodeFragmentDoc}`;
export const ProviderForCheckoutPropsFragmentDoc = gql`
    fragment ProviderForCheckoutProps on Provider {
  id
  policies {
    cancellation_policies {
      charge_time_offset_min
      fixed_amount
      percent
    }
  }
}
    `;
export const GetActiveProviderDocument = gql`
    query GetActiveProvider($slug: String! = "", $hasSlug: Boolean = false) {
  activeProviderSlug @client @export(as: "slug")
  activeProviderHasSlug @client @export(as: "hasSlug")
  providerBySlug(slug: $slug) @include(if: $hasSlug) {
    ...ProviderPops
  }
}
    ${ProviderPopsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActiveProviderGQL extends Apollo.Query<GetActiveProviderQuery, GetActiveProviderQueryVariables> {
    document = GetActiveProviderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProviderForCheckoutDocument = gql`
    query GetProviderForCheckout($providerId: String! = "") {
  activeProviderId @client @export(as: "providerId")
  provider(id: $providerId) {
    ...ProviderForCheckoutProps
  }
}
    ${ProviderForCheckoutPropsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProviderForCheckoutGQL extends Apollo.Query<GetProviderForCheckoutQuery, GetProviderForCheckoutQueryVariables> {
    document = GetProviderForCheckoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }