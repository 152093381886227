<ion-toolbar class="gb-top-toolbar-with-border" part="toolbar">
    <ion-back-button *ngIf="showBackButton"
                     slot="start"
                     [defaultHref]="backRoute"
                     text=""
                     color="dark"></ion-back-button>
    <div class="container">
        <div class="logo" [class.hide-logo-on-phone]="hideLogoOnPhone" (click)="goHome()">
            <ion-img *ngIf="logoImageUrl" [src]="logoImageUrl"></ion-img>
            <h1 class="gb-page-title" *ngIf="!hideLogoText && logoText">{{logoText}}</h1>
        </div>
        <ng-content></ng-content>
        <div class="buttons ion-margin-end">
            <div *ngIf="{user: user$ | async} as state">
                <ion-button *ngIf="state?.user?.uid" fill="clear" color="dark" (click)="navigateToReservations()">
                    <ion-icon name="calendar-outline" slot="start"></ion-icon>
                    Reservations
                </ion-button>
                <ion-button [id]="showListTriggerId"
                            fill="outline"
                            color="dark">
                    <ion-icon name="person" slot="start"></ion-icon>
                    <ion-label *ngIf="state?.user?.full_name as name; else noName">
                        {{name}}
                    </ion-label>
                    <ng-template #noName>
                        <ion-icon name="menu-outline"></ion-icon>
                    </ng-template>
                </ion-button>
                <ion-popover [trigger]="showListTriggerId"
                             [showBackdrop]="false"
                             [dismissOnSelect]="true" size="auto" id="profile-action-list-popover">
                    <ng-template>
                        <app-profile-page-list
                                showDetailIcon="false"
                                [user]="state?.user"
                                (loginButtonClicked)="loginButtonClicked.emit()"
                        ></app-profile-page-list>
                    </ng-template>
                </ion-popover>
            </div>
        </div>
    </div>
</ion-toolbar>
