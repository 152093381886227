import * as Types from './generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserInitiateDeleteAccountMutationVariables = Types.Exact<{
  channel?: Types.InputMaybe<Types.VerificationChannel>;
}>;


export type UserInitiateDeleteAccountMutation = { __typename?: 'Mutation', userInitiateDeleteAccount: { __typename?: 'InitiateDeleteAccountResponse', channel?: Types.VerificationChannel | null, message: string } };

export const UserInitiateDeleteAccountDocument = gql`
    mutation UserInitiateDeleteAccount($channel: VerificationChannel) {
  userInitiateDeleteAccount(data: {channel: $channel}) {
    channel
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserInitiateDeleteAccountGQL extends Apollo.Mutation<UserInitiateDeleteAccountMutation, UserInitiateDeleteAccountMutationVariables> {
    document = UserInitiateDeleteAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }